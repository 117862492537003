





















































































































.apply {
    position: relative;
    margin: auto;
    margin-top: 100px;
    width: 1080px;
    height: 680px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-bottom: 200px;
}

.img_left {
    width: 182px;
    height: 680px;
    //border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    float: left;
    position: relative;
}

.apply_right {
    margin-top: auto;
    width: 898px;
    height: 680px;
    float: right;
    background: #FFFFFF;
    position: relative;
    text-align: center;
}

.apply-body {
    text-align: left;
    font-size: 12;
    line-height: 3;
    top: 220px;
    width: 768px;
    height: 502px;
    margin: auto;
    left: 110px;
    position: absolute;
}

.apply-title1 {
    position: absolute;
    float: left;
    width: 192px;
    height: 38px;
    font-size: 38px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #1B212E;
    line-height: 48px;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    left: 90px;
    top: 60px;
}

.apply-title2 {
    position: absolute;
    width: 560px;
    height: 38px;
    font-size: 38px;
    font-family: SF Pro Display-Semibold, SF Pro Display;
    font-weight: 500;
    color: #1B212E;
    line-height: 48px;
    -webkit-background-clip: text;
    right: 90px;
    top: 60px;
    //     -webkit-text-fill-color: transparent;
}

.apply-title3 {
    width: 440px;
    height: 24px;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 300;
    color: #3D485D;
    line-height: 24px;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    position: absolute;
    left: 110px;
    top: 136px;
}

.type-img {
    float: left;
    width: 36px;
    height: 36px;
    left: 80px;
    top: 42px;
    // background: linear-gradient(90deg, #0139D0 0%, #00A3FF 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;
}

.type {
    float: left;
    left: 270px;
    top: 86px;
    width: 100px;
    height: 24px;
    background: #C3DAFF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;
}

.zoomlaFoot {
    text-align: center;
    height: 32px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #8085ad;
    line-height: 32px;
    position: fixed;
    bottom: -120px;
    position: relative;
}
